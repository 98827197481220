/*
 * hoverable.js
 * (C)2014 SHINGI Corporation.
 */
(function($) {
	$.fn.hoverable = function(args) {
		var defaults = {
			suffix : "-on",
			opacity : 1
		};
		var options = $.extend(defaults, args);
		var targets = $(this);
		targets.each(function() {
			var src = $(this).attr("src");
			var ext = "." + src.split(".").pop();
			var base = src.substr(0, src.length - ext.length);
			var opacity = $(this).css("opacity");
			$(this).hover(function() {
				$(this).attr("src", base + options["suffix"] + ext).css("opacity", options["opacity"]);
			}, function() {
				$(this).attr("src", base + ext).css("opacity", opacity);
			});

		});
	};
	
})(jQuery);
